.logo {
  display: flex;
  float: left;
  width: 120px;
  height: 32px;
  margin: 0 62px 0 -10px;
  background: rgba(255, 255, 255, 0.3);
}

.logo-svg {
  height: 32px;
  width: 100px;
  margin: 8px 0 0 3px;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.header {
  background-color: #f6f6f6 !important;
  z-index: 100;
  height: 50px !important;
  padding: 0 24px !important;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: unset;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #1890ff !important;
  font-weight: 500;
  font-size: 14px;
}

.ant-menu-title-content {
  transition: color 0.3s;
  font-weight: 500;
  font-size: 14px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -3px;
  margin-bottom: 0;
  padding: 0 20px;
  align-items: center;
  display: flex;
}

.info {
  display: flex;
  align-items: center;
}

.content {
  width: 85%;
}

.ant-menu-submenu-popup {
  width: auto !important;
}

.ant-menu-horizontal:hover .ant-menu-item:hover,
.ant-menu-submenu:hover > .ant-menu-submenu-title {
  width: auto;
}

@media screen and (min-width: 872px) {
  .ant-menu,
  .ant-menu-sub:hover > .ant-menu-vertical {
    display: flex;
  }
}
