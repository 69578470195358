.layout {
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.ant-layout-content{
    overflow-y: auto;
}

.ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
}
