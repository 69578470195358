.buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.filter-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.user-filter-container {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* margin-left: 24px; */
}

.ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight {
    padding-top: unset;
}

.ant-popover-placement-bottom .ant-popover-arrow, .ant-popover-placement-bottomLeft .ant-popover-arrow, .ant-popover-placement-bottomRight .ant-popover-arrow {
     top: unset;
     transform: unset;
}

label {
    margin-bottom: 8px;
    color: #1D1D1F;
    font-size: 14px;
}

.period {
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
}

.required:after {
    content:" *";
    color: #ff4d4f;
  }