.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #f0f2f5;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  align-items: center;
}

.logo-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
}

.login-form {
  max-width: 300px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 24px;
  background-color: #ffffff;
}

.login-form-forgot {
  margin-top: 8px;
  width: 100%;
}

/* .ant-col-rtl .login-form-forgot {
  float: right;
} */

.login-form-button {
  width: 100%;
}

.error {
  color: #ff4d4f;
}
